.am-activity-indicator {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  z-index: 99;
}

.am-activity-indicator-spinner {
  display: inline-block;
  width: 0.2rem;
  height: 0.2rem;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%22-2.125%20-1.875%2064%2064%22%3E%3Cpath%20fill%3D%22%23CCC%22%20d%3D%22M29.875-1.875c-17.673%200-32%2014.327-32%2032s14.327%2032%2032%2032%2032-14.327%2032-32-14.327-32-32-32zm0%2060.7c-15.85%200-28.7-12.85-28.7-28.7s12.85-28.7%2028.7-28.7%2028.7%2012.85%2028.7%2028.7-12.85%2028.7-28.7%2028.7z%22%2F%3E%3Cpath%20fill%3D%22%23108ee9%22%20d%3D%22M61.858%2030.34c.003-.102.008-.203.008-.305%200-11.43-5.996-21.452-15.01-27.113l-.013.026c-.24-.137-.515-.22-.81-.22-.912%200-1.65.738-1.65%201.65%200%20.654.384%201.215.937%201.482%207.963%205.1%2013.247%2014.017%2013.247%2024.176%200%20.147-.01.293-.01.44h.022c0%20.01-.004.02-.004.03%200%20.91.74%201.65%201.65%201.65s1.65-.74%201.65-1.65c0-.06-.012-.112-.018-.167z%22%2F%3E%3C%2Fsvg%3E");
  background-position: 50%;
  background-size: 100%;
  background-repeat: no-repeat;
  -webkit-animation: spinner-anime 1s linear infinite;
  animation: spinner-anime 1s linear infinite;
}

.am-activity-indicator-tip {
  font-size: 0.14rem;
  margin-left: 0.08rem;
  color: #000;
  opacity: 0.4;
}

.am-activity-indicator.am-activity-indicator-toast {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  text-align: center;
  z-index: 1999;
}

.am-activity-indicator.am-activity-indicator-toast .am-activity-indicator-spinner {
  margin: 0;
}

.am-activity-indicator.am-activity-indicator-toast .am-activity-indicator-toast {
  display: inline-block;
  position: relative;
  top: 0.04rem;
}

.am-activity-indicator-content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 0.15rem 0.15rem;
  border-radius: 0.07rem;
  background-clip: padding-box;
  color: #fff;
  background-color: rgba(58, 58, 58, 0.9);
  font-size: 0.15rem;
  line-height: 0.2rem;
}

.am-activity-indicator-spinner-lg {
  width: 0.32rem;
  height: 0.32rem;
}

@-webkit-keyframes spinner-anime {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner-anime {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}