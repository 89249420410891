.am-toast {
  position: fixed;
  width: 100%;
  z-index: 1999;
  font-size: 0.14rem;
  text-align: center;
}

.am-toast > span {
  max-width: 50%;
}

.am-toast.am-toast-mask {
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  left: 0;
  top: 0;
  -webkit-transform: translateZ(0.01rem);
  transform: translateZ(0.01rem);
}

.am-toast.am-toast-nomask {
  position: fixed;
  max-width: 50%;
  width: auto;
  left: 50%;
  top: 50%;
  -webkit-transform: translateZ(0.01rem);
  transform: translateZ(0.01rem);
}

.am-toast.am-toast-nomask .am-toast-notice {
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.am-toast-notice-content .am-toast-text {
  min-width: 0.6rem;
  border-radius: 0.03rem;
  color: #fff;
  background-color: rgba(58, 58, 58, 0.9);
  line-height: 1.5;
  padding: 0.09rem 0.15rem;
}

.am-toast-notice-content .am-toast-text.am-toast-text-icon {
  border-radius: 0.05rem;
  padding: 0.15rem 0.15rem;
}

.am-toast-notice-content .am-toast-text.am-toast-text-icon .am-toast-text-info {
  margin-top: 0.06rem;
}