.am-icon {
  fill: currentColor;
  background-size: cover;
  width: 0.22rem;
  height: 0.22rem;
}

.am-icon-xxs {
  width: 0.15rem;
  height: 0.15rem;
}

.am-icon-xs {
  width: 0.18rem;
  height: 0.18rem;
}

.am-icon-sm {
  width: 0.21rem;
  height: 0.21rem;
}

.am-icon-md {
  width: 0.22rem;
  height: 0.22rem;
}

.am-icon-lg {
  width: 0.36rem;
  height: 0.36rem;
}

.am-icon-loading {
  -webkit-animation: cirle-anim 1s linear infinite;
  animation: cirle-anim 1s linear infinite;
}

@-webkit-keyframes cirle-anim {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes cirle-anim {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}