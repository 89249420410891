.am-image-picker-list {
  padding: 0.09rem 0.08rem 0;
  margin-bottom: 0.15rem;
}

.am-image-picker-list .am-flexbox {
  margin-bottom: 0.06rem;
}

.am-image-picker-list .am-flexbox .am-flexbox-item {
  position: relative;
  margin-right: 0.05rem;
  margin-left: 0;
}

.am-image-picker-list .am-flexbox .am-flexbox-item:after {
  display: block;
  content: ' ';
  padding-bottom: 100%;
}

.am-image-picker-list .am-image-picker-item {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
  height: 100%;
}

.am-image-picker-list .am-image-picker-item .am-image-picker-item-remove {
  width: 0.15rem;
  height: 0.15rem;
  position: absolute;
  right: 0.06rem;
  top: 0.06rem;
  text-align: right;
  vertical-align: top;
  z-index: 2;
  background-size: 0.15rem auto;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20width%3D'16'%20height%3D'16'%20viewBox%3D'0%200%2016%2016'%20version%3D'1.1'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%3E%3Cg%20id%3D'Page-1'%20stroke%3D'none'%20stroke-width%3D'1'%20fill%3D'none'%20fill-rule%3D'evenodd'%3E%3Ccircle%20id%3D'Oval-98'%20fill-opacity%3D'0.4'%20fill%3D'%23404040'%20cx%3D'8'%20cy%3D'8'%20r%3D'8'%3E%3C%2Fcircle%3E%3Cpath%20d%3D'M11.8979743%2C11.8990375%20L11.8979743%2C11.8990375%20C11.7633757%2C12.0336542%2011.5447877%2C12.0336542%2011.4101891%2C11.8990375%20L8%2C8.48838931%20L4.5887341%2C11.8990375%20C4.45413554%2C12.0336542%204.23554748%2C12.0336542%204.10094892%2C11.8990375%20L4.10094892%2C11.8990375%20C3.96635036%2C11.7644208%203.96635036%2C11.5458033%204.10094892%2C11.4111866%20L7.51221482%2C8.00053847%20L4.10202571%2C4.58881335%20C3.96742715%2C4.45419667%203.96742715%2C4.23557919%204.10202571%2C4.10096251%20L4.10202571%2C4.10096251%20C4.23662427%2C3.96634583%204.45521233%2C3.96634583%204.58981089%2C4.10096251%20L8%2C7.51268762%20L11.4112659%2C4.10203944%20C11.5458645%2C3.96742276%2011.7644525%2C3.96742276%2011.8990511%2C4.10203944%20L11.8990511%2C4.10203944%20C12.0336496%2C4.23665612%2012.0336496%2C4.45527361%2011.8990511%2C4.58989029%20L8.48778518%2C8.00053847%20L11.8979743%2C11.4122636%20C12.0325729%2C11.5468803%2012.0325729%2C11.7644208%2011.8979743%2C11.8990375%20L11.8979743%2C11.8990375%20Z'%20id%3D'Shape'%20fill%3D'%23FFFFFF'%20transform%3D'translate(8.000000%2C%208.000000)%20scale(1%2C%20-1)%20translate(-8.000000%2C%20-8.000000)%20'%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}

.am-image-picker-list .am-image-picker-item .am-image-picker-item-content {
  height: 100%;
  width: 100%;
  border-radius: 0.03rem;
  background-size: cover;
}

.am-image-picker-list .am-image-picker-item img {
  width: 100%;
}

.am-image-picker-list .am-image-picker-upload-btn {
  box-sizing: border-box;
  border-radius: 0.03rem;
  border: 1PX solid #ddd;
  background-color: #fff;
}

.am-image-picker-list .am-image-picker-upload-btn:before,
.am-image-picker-list .am-image-picker-upload-btn:after {
  width: 1PX;
  height: 0.25rem;
  content: " ";
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: #ccc;
}

.am-image-picker-list .am-image-picker-upload-btn:after {
  width: 0.25rem;
  height: 1PX;
}

.am-image-picker-list .am-image-picker-upload-btn-active {
  background-color: #ddd;
}

.am-image-picker-list .am-image-picker-upload-btn input {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
}