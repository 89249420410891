.am-list-header {
  padding: 0.15rem 0.15rem 0.09rem 0.15rem;
  font-size: 0.14rem;
  color: #888;
  width: 100%;
  box-sizing: border-box;
}

.am-list-footer {
  padding: 0.09rem 0.15rem 0.15rem 0.15rem;
  font-size: 0.14rem;
  color: #888;
}

.am-list-body {
  position: relative;
  background-color: #fff;
  border-top: 1PX solid #ddd;
  border-bottom: 1PX solid #ddd;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  html:not([data-scale]) .am-list-body {
    border-top: none;
  }

  html:not([data-scale]) .am-list-body::before {
    content: '';
    position: absolute;
    background-color: #ddd;
    display: block;
    z-index: 1;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    width: 100%;
    height: 1PX;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transform: scaleY(0.5);
    transform: scaleY(0.5);
  }
}

@media (-webkit-min-device-pixel-ratio: 2) and (-webkit-min-device-pixel-ratio: 3), (min-resolution: 2dppx) and (min-resolution: 3dppx) {
  html:not([data-scale]) .am-list-body::before {
    -webkit-transform: scaleY(0.33);
    transform: scaleY(0.33);
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  html:not([data-scale]) .am-list-body {
    border-bottom: none;
  }

  html:not([data-scale]) .am-list-body::after {
    content: '';
    position: absolute;
    background-color: #ddd;
    display: block;
    z-index: 1;
    top: auto;
    right: auto;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1PX;
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transform: scaleY(0.5);
    transform: scaleY(0.5);
  }
}

@media (-webkit-min-device-pixel-ratio: 2) and (-webkit-min-device-pixel-ratio: 3), (min-resolution: 2dppx) and (min-resolution: 3dppx) {
  html:not([data-scale]) .am-list-body::after {
    -webkit-transform: scaleY(0.33);
    transform: scaleY(0.33);
  }
}

.am-list-body div:not(:last-child) .am-list-line {
  border-bottom: 1PX solid #ddd;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  html:not([data-scale]) .am-list-body div:not(:last-child) .am-list-line {
    border-bottom: none;
  }

  html:not([data-scale]) .am-list-body div:not(:last-child) .am-list-line::after {
    content: '';
    position: absolute;
    background-color: #ddd;
    display: block;
    z-index: 1;
    top: auto;
    right: auto;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1PX;
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transform: scaleY(0.5);
    transform: scaleY(0.5);
  }
}

@media (-webkit-min-device-pixel-ratio: 2) and (-webkit-min-device-pixel-ratio: 3), (min-resolution: 2dppx) and (min-resolution: 3dppx) {
  html:not([data-scale]) .am-list-body div:not(:last-child) .am-list-line::after {
    -webkit-transform: scaleY(0.33);
    transform: scaleY(0.33);
  }
}

.am-list-item {
  position: relative;
  display: -webkit-flex;
  display: flex;
  padding-left: 0.15rem;
  min-height: 0.44rem;
  background-color: #fff;
  vertical-align: middle;
  overflow: hidden;
  transition: background-color 200ms;
  -webkit-align-items: center;
  align-items: center;
  /* list左图片显示*/
}

.am-list-item .am-list-ripple {
  position: absolute;
  background: transparent;
  display: inline-block;
  overflow: hidden;
  will-change: box-shadow, transform;
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1), color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  outline: none;
  cursor: pointer;
  border-radius: 100%;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.am-list-item .am-list-ripple.am-list-ripple-animate {
  background-color: hsla(0, 0%, 62%, 0.2);
  -webkit-animation: ripple 1s linear;
  animation: ripple 1s linear;
}

.am-list-item.am-list-item-top .am-list-line {
  -webkit-align-items: flex-start;
  align-items: flex-start;
}

.am-list-item.am-list-item-top .am-list-line .am-list-arrow {
  margin-top: 0.02rem;
}

.am-list-item.am-list-item-middle .am-list-line {
  -webkit-align-items: center;
  align-items: center;
}

.am-list-item.am-list-item-bottom .am-list-line {
  -webkit-align-items: flex-end;
  align-items: flex-end;
}

.am-list-item.am-list-item-error .am-list-line .am-list-extra {
  color: #f50;
}

.am-list-item.am-list-item-error .am-list-line .am-list-extra .am-list-brief {
  color: #f50;
}

.am-list-item.am-list-item-active {
  background-color: #ddd;
}

.am-list-item.am-list-item-disabled .am-list-line .am-list-content,
.am-list-item.am-list-item-disabled .am-list-line .am-list-extra {
  color: #bbb;
}

.am-list-item img {
  width: 0.22rem;
  height: 0.22rem;
  vertical-align: middle;
}

.am-list-item .am-list-thumb:first-child {
  margin-right: 0.15rem;
}

.am-list-item .am-list-thumb:last-child {
  margin-left: 0.08rem;
}

.am-list-item .am-list-line {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1;
  flex: 1 1;
  -webkit-align-self: stretch;
  align-self: stretch;
  padding-right: 0.15rem;
  overflow: hidden;
  /* list左侧主内容*/
  /* list右补充内容*/
  /* 辅助性文字*/
  /* list右侧箭头*/
}

.am-list-item .am-list-line .am-list-content {
  -webkit-flex: 1 1;
  flex: 1 1;
  color: #000;
  font-size: 0.17rem;
  line-height: 1.5;
  text-align: left;
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-top: 0.07rem;
  padding-bottom: 0.07rem;
}

.am-list-item .am-list-line .am-list-extra {
  -webkit-flex-basis: 36%;
  flex-basis: 36%;
  color: #888;
  font-size: 0.16rem;
  line-height: 1.5;
  text-align: right;
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-top: 0.07rem;
  padding-bottom: 0.07rem;
}

.am-list-item .am-list-line .am-list-title {
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.am-list-item .am-list-line .am-list-brief {
  color: #888;
  font-size: 0.15rem;
  line-height: 1.5;
  margin-top: 0.06rem;
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.am-list-item .am-list-line .am-list-arrow {
  display: block;
  width: 0.15rem;
  height: 0.15rem;
  margin-left: 0.08rem;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20width%3D%2216%22%20height%3D%2226%22%20viewBox%3D%220%200%2016%2026%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%3Cg%20id%3D%22UI-KIT_%E5%9F%BA%E7%A1%80%E5%85%83%E4%BB%B6%22%20stroke%3D%22none%22%20stroke-width%3D%221%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%3Cg%20id%3D%229.9%E5%9F%BA%E7%A1%80%E5%85%83%E4%BB%B6%22%20transform%3D%22translate(-5809.000000%2C%20-8482.000000)%22%20fill%3D%22%23C7C7CC%22%3E%3Cpolygon%20id%3D%22Disclosure-Indicator%22%20points%3D%225811%208482%205809%208484%205820.5%208495%205809%208506%205811%208508%205825%208495%22%3E%3C%2Fpolygon%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  visibility: hidden;
}

.am-list-item .am-list-line .am-list-arrow-horizontal {
  visibility: visible;
}

.am-list-item .am-list-line .am-list-arrow-vertical {
  visibility: visible;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.am-list-item .am-list-line .am-list-arrow-vertical-up {
  visibility: visible;
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}

.am-list-item .am-list-line-multiple {
  padding: 0.125rem 0.15rem 0.125rem 0;
}

.am-list-item .am-list-line-multiple .am-list-content {
  padding-top: 0;
  padding-bottom: 0;
}

.am-list-item .am-list-line-multiple .am-list-extra {
  padding-top: 0;
  padding-bottom: 0;
}

.am-list-item .am-list-line-wrap .am-list-content {
  white-space: normal;
}

.am-list-item .am-list-line-wrap .am-list-extra {
  white-space: normal;
}

.am-list-item select {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  padding: 0;
  border: 0;
  font-size: 0.17rem;
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
}

@-webkit-keyframes ripple {
  100% {
    opacity: 0;
    -webkit-transform: scale(2.5);
    transform: scale(2.5);
  }
}

@keyframes ripple {
  100% {
    opacity: 0;
    -webkit-transform: scale(2.5);
    transform: scale(2.5);
  }
}