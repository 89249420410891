.am-wingblank {
  margin-left: 0.08rem;
  margin-right: 0.08rem;
}

.am-wingblank.am-wingblank-sm {
  margin-left: 0.05rem;
  margin-right: 0.05rem;
}

.am-wingblank.am-wingblank-md {
  margin-left: 0.08rem;
  margin-right: 0.08rem;
}

.am-wingblank.am-wingblank-lg {
  margin-left: 0.15rem;
  margin-right: 0.15rem;
}