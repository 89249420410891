.am-indexed-list-section-body.am-list-body,
.am-indexed-list-section-body.am-list-body .am-list-item:last-child .am-list-line {
  border-bottom: 0;
}

.am-indexed-list-section-body.am-list-body:after,
.am-indexed-list-section-body.am-list-body .am-list-item:last-child .am-list-line:after {
  display: none !important;
}

.am-indexed-list-section-header.am-list-body,
.am-indexed-list-section-header.am-list-body .am-list-item .am-list-line {
  border-bottom: 0;
}

.am-indexed-list-section-header.am-list-body:after,
.am-indexed-list-section-header.am-list-body .am-list-item .am-list-line:after {
  display: none !important;
}

.am-indexed-list-section-header .am-list-item {
  height: 0.3rem;
  min-height: 0.3rem;
  background-color: #f5f5f9;
}

.am-indexed-list-section-header .am-list-item .am-list-line {
  height: 0.3rem;
  min-height: 0.3rem;
}

.am-indexed-list-section-header .am-list-item .am-list-content {
  font-size: 0.14rem !important;
  color: #888 !important;
}

.am-indexed-list-quick-search-bar {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 0;
  text-align: center;
  color: #108ee9;
  font-size: 0.16rem;
  list-style: none;
  padding: 0;
}

.am-indexed-list-quick-search-bar li {
  padding: 0 0.05rem;
}

.am-indexed-list-quick-search-bar-over {
  background-color: rgba(0, 0, 0, 0.4);
}

.am-indexed-list-qsindicator {
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -0.15rem auto auto -0.3rem;
  width: 0.6rem;
  height: 0.3rem;
  background: transparent;
  opacity: 0.7;
  color: #0af;
  font-size: 0.2rem;
  border-radius: 0.3rem;
  z-index: 1999;
  text-align: center;
  line-height: 0.3rem;
}

.am-indexed-list-qsindicator-hide {
  display: none;
}